import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
import {useNavigate} from "react-router-dom";
import { useStore } from "../../app/stores/store";
import ResponsiveCategoryPage from "./categories/ResponsiveCategoriesPage";
import AboutUsPage from "../../features/home/AboutUsPage";
import ResponsiveProductPage from "./categories/ResponsiveProductPage";
import useEmblaCarousel from 'embla-carousel-react'

export default observer(function ResponsiveHomePage() {
    const [toggleBtn, setToggleBtn] = useState(true);
    const navigate = useNavigate();
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore,categoryStore,productStore} = useStore();    
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };  
  const {categoryByName,loadCategoryList,categoryreload,setCategoryReload} = categoryStore;
  const {productreload,productByName,loadProductList,setProductReload} = productStore;
  const options = { align: 'start' }
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  useEffect(() => {    
    if(categoryreload || categoryByName === undefined)
    {
        loadCategoryList().then(()=>{
            setCategoryReload(false);
        })
    }
    if(categoryByName !== undefined)
    {
        categoryByName.map((category,index)=>{
            
        })
    }
  },[categoryreload])
  useEffect(() => {    
    if(productreload || productByName === undefined)
    {
        loadProductList().then(()=>{
            setProductReload(false);
        })
    }
    if(productByName !== undefined)
    {
      productByName.map((product,index)=>{
            
        })
    }
  },[productreload])
    return (
        <>
        <section data-bs-version="5.1" className="article01 markm5 cid-ub15s349gg" id="article01-6">
        <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="content-wrapper">                    
                    <h2 className="mbr-section-title mbr-fonts-style display-7">Wholesale and direct to consumer sales of the finest organic agricultural products</h2>
                    <div className="image-wrapper">
                        <img src="/assets/images/sack-rice-with-rice-plant-place-wooden-floor-2576x1717.webp" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>    
</section>
<section data-bs-version="5.1" className="features04 markm5 cid-ub15s1vKU7" id="features04-5">

    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="title-wrapper">
                    <h3 className="mbr-section-title mbr-fonts-style display-1">CATALOGUE</h3>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-lg-6 item features-image">
                <a href="#">
                    <div className="item-wrapper">
                        <div className="item-img">
                            <img src="/assets/images/brownriceimages-262x192.webp" alt="" title="" />
                        </div>
                        <div className="item-content">
                            <div className="list-wrapper">
                                <ul className="list mbr-fonts-style display-4">
                                    <li className="item-wrap">Rice (Basmati Rice / Kolam Rice / Brown Rice)</li>
                                </ul>
                            </div>
                            <h4 className="item-title mbr-fonts-style display-7">Rice comes in a stunning array of varieties, each with its own unique flavor, texture, and culinary uses. we deals in Basmati Rice, Broken <strong>Basmati Rice, Kolam Rice, Brown Rice</strong>.</h4>
                            
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-12 col-lg-6 item features-image">
                <a href="#">
                    <div className="item-wrapper">
                        <div className="item-img">
                            <img src="/assets/images/dalcategorypic-400x337.webp" alt="" title="" />
                        </div>
                        <div className="item-content">
                            <div className="list-wrapper">
                                <ul className="list mbr-fonts-style display-4">
                                    <li className="item-wrap">Pulses (Chana / Moong/ Toor/ Urad / Red Lintils)</li>
                                </ul>
                            </div>
                            <h4 className="item-title mbr-fonts-style display-7">Our Dals/Pulses have unique nutritional content and benefits. It helps to strengthen your body and boost immunity. Our category of Dals/Pulses includes <strong>Chana dal, Moong dal, Polished toor dal, Unpolished toor dal, Urad Dal, and Red Lintils</strong>.</h4>
                            
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<section data-bs-version="5.1" className="slider01 mbr-embla markm5 cid-ub15s5Muna mbr-fullscreen" id="slider01-8">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
            <div className="title-wrapper">
            <h2 className="mbr-section-title mbr-fonts-style display-2">Product</h2>
                </div>
                <div className="embla" data-skip-snaps="true" data-align="center" data-contain-scroll="trimSnaps" data-loop="true" data-auto-play-interval="5" data-draggable="true">
                    <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">                       
                        {!productreload && productByName.map((product,key)=>( 
                            <div className="embla__slide slider-image item active" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                                <div className="slide-content">
                                    <div className="social-wrapper">
                                        
                                    </div>
                                    <div className="item-wrapper">
                                        <a style={{cursor:'pointer'}} onClick={() => navigate(`product/${product.id}`)}>
                                            <div className="item-img">
                                                <img src={product.productImage} alt={product.title} data-slide-to={key} data-bs-slide-to={key} />
                                                <div className="icon-wrapper">
                                                    <span className="mbr-iconfont mobi-mbri-up mobi-mbri"></span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="item-content">
                                        <h6 className="item-subtitle mbr-fonts-style display-5">{product.name}</h6>
                                        <h5 className="item-title mbr-fonts-style display-7">{product.title}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                            </div>                            
                    </div>
                    <button className="embla__button embla__button--prev" onClick={()=>{
                        if (!emblaApi) return;
                        emblaApi.scrollPrev();
                    }}>
                        <span className="mobi-mbri mobi-mbri-arrow-prev mbr-iconfont visually-hidden" aria-hidden="true"></span>
                        <span className=".mbr-prev">PREV —</span>
                    </button>
                    <button className="embla__button embla__button--next" onClick={()=>{
                        if (!emblaApi) return;
                        emblaApi.scrollNext();
                    }}>
                        <span className="mobi-mbri mobi-mbri-arrow-next mbr-iconfont visually-hidden" aria-hidden="true"></span>
                        <span className=".mbr-next">— NEXT</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
{/* <section data-bs-version="5.1" className="slider02 mbr-embla forwardm5 cid-ubaCWixyBR" id="slider02-1g">
    
    

    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="title-wrapper">
                    <h2 className="mbr-section-title mbr-fonts-style display-2">
                        Product</h2>
                </div>
            </div>
            <div className="col-12">
                <div className="embla" data-skip-snaps="true" data-align="center" data-contain-scroll="trimSnaps" data-auto-play-interval="3" data-draggable="true">
                    <div className="embla__viewport" ref={emblaRef}>
                        <div className="embla__container">
                            
                            <div className="embla__slide slider-image item active" style={{marginLeft: "12px", marginRight: "12px"}}>
                                <div className="slide-content">
                                    <div className="item-wrapper">
                                        <div className="item-img">
                                            <img src="assets/images/organicbasmatirice.webp" alt="" data-slide-to="0" data-bs-slide-to="0" />
                                            <div className="title-wrap">
                                                <h4 className="item-title mbr-fonts-style display-4">Organic Basmati Rice</h4>
                                            </div>
                                        </div>
                                        <div className="item-content">
                                            
                                            <p className="item-text mbr-fonts-style display-7">
                                                Organic Basmati Rice:
<span>Organic Basmati Rice is a premium variety of Basmati rice that's grown and cultivated using organic farming practices, without the use of synthetic fertilizers, pesticides, or genetically modified organisms (GMOs)</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mbr-section-btn"><a href="" className="btn item-btn btn-danger display-4">
                                            Read More
                                        </a></div>
                                </div>
                            </div><div className="embla__slide slider-image item" style={{marginLeft: "12px", marginRight: "12px"}}>
                                <div className="slide-content">
                                    <div className="item-wrapper">
                                        <div className="item-img">
                                            <img src="assets/images/redlentil2-225x225.webp" alt="" data-slide-to="1" data-bs-slide-to="1" />
                                            <div className="title-wrap">
                                                <h4 className="item-title mbr-fonts-style display-4">Organic Masoor Dal - Red Lentil</h4>
                                            </div>
                                        </div>
                                        <div className="item-content">
                                            
                                            <p className="item-text mbr-fonts-style display-7">
                                                Organic Masoor Dal - Red Lentil:
                                                <span>Organic Masoor Dal is a nutritional powerhouse, packed with protein, fiber, vitamins, and minerals. As an excellent source of plant-based protein, Organic Masoor Dal is an ideal choice for vegetarians and vegans looking to meet their protein needs. It's also rich in folate, iron, potassium, and magnesium, making it a wholesome addition to a balanced diet.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mbr-section-btn"><a href="" className="btn item-btn btn-success display-4">
                                            Read More
                                        </a></div>
                                </div>
                            </div>
                            <div className="embla__slide slider-image item" style={{marginLeft: "12px", marginRight: "12px"}}>
                                <div className="slide-content">
                                    <div className="item-wrapper">
                                        <div className="item-img">
                                            <img src="assets/images/rice-9-500x500.webp" alt="" />
                                            <div className="title-wrap">
                                                <h4 className="item-title mbr-fonts-style display-4">Raw Basmati Rice</h4>
                                            </div>
                                        </div>
                                        <div className="item-content">
                                            
                                            <p className="item-text mbr-fonts-style display-7">
                                                Raw Basmati Rice:
                                                <span>Raw Basmati Rice, revered for its long, slender grains and exquisite aroma, is the foundation of countless culinary creations around the world. From fragrant biryanis to creamy risottos, Raw Basmati Rice offers a versatile canvas for chefs and home cooks alike to express their creativity and indulge in the rich flavors of global cuisine.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mbr-section-btn"><a href="" className="btn item-btn btn-success display-4">
                                            Read More
                                        </a></div>
                                </div>
                            </div>
                            <div className="embla__slide slider-image item" style={{marginLeft: "12px", marginRight: "12px"}}>
                                <div className="slide-content">
                                    <div className="item-wrapper">
                                        <div className="item-img">
                                            <img src="assets/images/mix-dal-309x309.webp" alt="" />
                                            <div className="title-wrap">
                                                <h4 className="item-title mbr-fonts-style display-4">Mix Dal</h4>
                                            </div>
                                        </div>
                                        <div className="item-content">
                                            
                                            <p className="item-text mbr-fonts-style display-7">
                                                Mix dal:
                                               <span> Mix dal typically consists of a combination of lentils such as split red lentils (masoor dal), split chickpeas (chana dal), split mung beans (moong dal), and split pigeon peas (toor dal). Each lentil brings its unique flavor profile and nutritional benefits to the mix, resulting in a deliciously complex and satisfying dish</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mbr-section-btn"><a href="" className="btn item-btn btn-success display-4">
                                            Read More
                                        </a></div>
                                </div>
                            </div>
                            <div className="embla__slide slider-image item" style={{marginLeft: "12px", marginRight: "12px"}}>
                                <div className="slide-content">
                                    <div className="item-wrapper">
                                        <div className="item-img">
                                            <img src="assets/images/whitesellabasmatirice.webp" alt="" />
                                            <div className="title-wrap">
                                                <h4 className="item-title mbr-fonts-style display-4">
                                                    White Sella Basmati Rice
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="item-content">
                                            
                                            <p className="item-text mbr-fonts-style display-7">
                                                White Sella Basmati Rice:
                                                <span>White Sella Basmati Rice, renowned for its long, slender grains and exquisite aroma, is a premium variety of Basmati rice prized for its exceptional quality and versatility in culinary applications</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mbr-section-btn">
                                        <a href="" className="btn item-btn btn-primary-outline display-4">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="embla__slide slider-image item" style={{marginLeft: "12px", marginRight: "12px"}}>
                                <div className="slide-content">
                                    <div className="item-wrapper">
                                        <div className="item-img">
                                            <img src="assets/images/dalbanner.webp" alt="" />
                                            <div className="title-wrap">
                                                <h4 className="item-title mbr-fonts-style display-4">
                                                    Toor Dal
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="item-content">
                                            
                                            <p className="item-text mbr-fonts-style display-7">
                                                Toor Dal:
                                                <span>Toor dal boasts a deliciously nutty and slightly sweet flavor profile, making it a versatile ingredient in a wide range of dishes. Whether used in traditional Indian dal recipes, soups, stews, or even as a base for savory pancakes and fritters, Toor dal adds depth and richness to any dish it graces.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mbr-section-btn">
                                        <a href="" className="btn item-btn btn-primary-outline display-4">
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="embla__button embla__button--prev">
                        <span className="mobi-mbri mobi-mbri-left mbr-iconfont" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden visually-hidden visually-hidden">Previous</span>
                    </button>
                    <button className="embla__button embla__button--next">
                        <span className="mobi-mbri mobi-mbri-right mbr-iconfont" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden visually-hidden visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section> */}
        
<section data-bs-version="5.1" className="list03 markm5 cid-ub15s4rKRT mbr-parallax-background" id="list03-7">
    
    <div className="mbr-overlay" style={{opacity: "0.3", backgroundColor:"rgb(0, 0, 0)"}}></div>
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-5">
                <div className="title-wrapper">
                    <div className="title-wrap">
                        <h5 className="mbr-section-subtitle mbr-fonts-style display-4">We offer quality assured range of Rice &amp; pulses</h5>
                        <h2 className="mbr-section-title mbr-fonts-style display-1"><strong>Key to Our Success</strong><br/>&nbsp;</h2>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-7">
                <div id="bootstrap-accordion_18" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
                    <div className="card">
                        <div className="card-header" role="tab" id="headingOne">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse1_18" aria-expanded="false" aria-controls="collapse1">
                                <h4 className="panel-title-edit mbr-fonts-style display-2">
                                    1
                                </h4>
                                <div className="icon-wrap">
                                    <span className="sign mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                                </div>
                            </a>
                        </div>
                        <div id="collapse1_18" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_18">
                            <div className="panel-body">
                                <p className="mbr-fonts-style panel-subtitle display-5">
                                    Automation</p>
                                <p className="mbr-fonts-style panel-text display-4">Rice comes in a stunning array of varieties, each with its own unique flavor, texture, and culinary uses. we deals in Basmati Rice, Broken Basmati Rice, Kolam Rice, Brown Rice.</p>
                            </div>
                        </div>
                        <img src="/assets/images/ricemillautomation-398x224.webp" alt="Automation" />
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingTwo">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse2_18" aria-expanded="false" aria-controls="collapse2">
                                <h4 className="panel-title-edit mbr-fonts-style display-2">
                                    2
                                </h4>
                                <div className="icon-wrap">
                                    <span className="sign mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                                </div>
                            </a>
                        </div>
                        <div id="collapse2_18" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_18">
                            <div className="panel-body">
                                <p className="mbr-fonts-style panel-subtitle display-5">
                                    Quality</p>
                                <p className="mbr-fonts-style panel-text display-4">Quality control is an ongoing process that requires continuous monitoring, evaluation, and improvement</p>
                            </div>
                        </div>
                        <img src="/assets/images/qualitycontrol-398x266.webp" alt="Quality" />
                    </div>
                    <div className="card">
                        <div className="card-header" role="tab" id="headingThree">
                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse3_18" aria-expanded="false" aria-controls="collapse3">
                                <h4 className="panel-title-edit mbr-fonts-style display-2">
                                    3
                                </h4>
                                <div className="icon-wrap">
                                    <span className="sign mbr-iconfont mobi-mbri-left mobi-mbri"></span>
                                </div>
                            </a>
                        </div>
                        <div id="collapse3_18" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_18">
                            <div className="panel-body">
                                <p className="mbr-fonts-style panel-subtitle display-5">
                                    Private Label</p>
                                <p className="mbr-fonts-style panel-text display-4">Our private label rice products are sourced from trusted suppliers known for their commitment to quality and sustainability.</p>
                            </div>
                        </div>
                        <img src="/assets/images/private-1-398x493.webp" alt="Private Label" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>        
        </>
    )
})