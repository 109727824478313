import { observer } from 'mobx-react-lite';
import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
export default observer(function ContactUsPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
    return (
        <section data-bs-version="5.1" className="contact3 cid-ub15scv7ch" id="contact3-d">
    <div className="container">
        <div className="mbr-section-head">
            <h3 className="mbr-section-title mbr-fonts-style display-2">
                <strong>FOLLOW US</strong>
            </h3>
            <p className="mbr-text mbr-fonts-style display-7">
                Thank you for choosing <strong>Rajan Enterprises</strong>. We appreciate your business and look forward to serving you.</p>
        </div>
        <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
                <div className="card-wrapper">
                    <div className="image-wrapper">
                        <span className="mbr-iconfont mobi-mbri-phone mobi-mbri"></span>
                    </div>
                    <div className="text-wrapper">
                        <h6 className="card-title mbr-fonts-style mb-1 display-5">
                            <strong>PHONE</strong>
                        </h6>
                        <p className="mbr-text mbr-fonts-style display-7">
                            <a href="tel:+1919534073353" className="text-primary">919576363981</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
                <div className="card-wrapper">
                    <div className="image-wrapper">
                        <span className="mbr-iconfont mobi-mbri-letter mobi-mbri"></span>
                    </div>
                    <div className="text-wrapper">
                        <h6 className="card-title mbr-fonts-style mb-1 display-5">
                            <strong>EMAIL</strong>
                        </h6>
                        <p className="mbr-text mbr-fonts-style display-7">
                            <a href="mailto:rajanenterprises067@gmail.com" className="text-primary">rajanenterprises067@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
                <div className="card-wrapper">
                    <div className="image-wrapper">
                        <span className="mbr-iconfont mobi-mbri-globe mobi-mbri"></span>
                    </div>
                    <div className="text-wrapper">
                        <h6 className="card-title mbr-fonts-style mb-1 display-5">
                            <strong>ADDRESS</strong>
                        </h6>
                        <p className="mbr-text mbr-fonts-style display-7">
                        SHOP NO. 5 KHASRA NO. 133 MAIN ROAD NEB SARAI SOUTH DELHI NEW DELHI 110068
                        </p>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</section>
    )
})