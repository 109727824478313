import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyBQ4OqIXlSffvCma24uIEhLuQ3sJK1u6Xc",
  authDomain: "rajanenterprises-b5e58.firebaseapp.com",
  projectId: "rajanenterprises-b5e58",
  storageBucket: "rajanenterprises-b5e58.appspot.com",
  messagingSenderId: "340687572568",
  appId: "1:340687572568:web:948c140267b8c0fe89842d"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);