import { observer } from 'mobx-react-lite';
import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import ModalContainer from '../../app/common/modals/ModalContainer';
import { ToastContainer } from 'react-toastify';
import Navbar from '../../app/common/controls/Navbar';
import Content from '../../app/common/controls/Content';
export default observer(function AboutUsPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
    return (
<section data-bs-version="5.1" className="buildm5 features3 cid-ub1nBPRwUM" id="features3-t">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 content-head">
        <div className="card-wrapper mb-5">
          <div className="card-box align-center">
            <h4 className="mbr-section-title mbr-fonts-style mb-0 display-7">About Us</h4>
            <div className="line"></div>
            <p className="mbr-text mbr-fonts-style mt-4 pb-4 display-5">
              Your Trusted Partner in Quality Rice and Pulses</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row content-row">
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">01</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Our Mission</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">To deliver premium-quality rice and pulses that meet the highest standards of taste, nutrition, and safety. We strive to exceed our customers' expectations by consistently providing products value.<br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">02</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Quality Assurance</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">Quality is at the heart of everything we do. From seed selection and cultivation to processing, packaging, and distribution, we maintain strict quality control measures at every stage of production<br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">03</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Sustainable Practices</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">We are committed to sustainability and environmental stewardship. Our farming practices prioritize soil health, water conservation, and biodiversity, while minimizing our carbon footprint and reducing waste. <br/></p>
          <div className="line2"></div>
        </div>
      </div>
      <div className="item features-without-image col-12 col-md-6 col-lg-6 item-mb">
        <div className="item-wrapper">
          <div className="title mb-3">
            <span className="num mbr-fonts-style display-7">04</span>
          </div>
          <h4 className="card-title mbr-fonts-style pb-2 display-5">Customer Satisfaction</h4>
          <p className="mbr-text card-text mbr-fonts-style mb-0 display-7">At <strong>Rajan Enterprises</strong>, we believe in building long-lasting relationships with our customers based on trust, reliability, and mutual respect. Our dedicated team is committed to provide prompt delivery, support to meet customer need.<br/></p>
          <div className="line2"></div>
        </div>
      </div>


    </div>
  </div>
</section>
    )
})